<template>
  <div class="contacts__branches-item">
      <h4>{{ title }}</h4>
      <div class="contacts__branches-address">
        <p v-if="address">{{address}}</p>
        <p v-if="phone">{{$t('contacts_contact_number', { phone: phone })}}</p>
        <!-- <p>Email: adress@email.com</p> -->
      </div>
      <div class="contacts__branches-time">
        <p v-if="schedule" v-html="schedule"></p>
        <div class="contacts__branches-ramadan" v-if="ramadanSchedule">
          <p v-if="titleAddSchedule" v-html="titleAddSchedule"></p>
          <p v-html="ramadanSchedule"></p>
        </div>
      </div>
      <a href="#" @click.prevent="onShowLocation">{{$t('contacts_show_location')}}</a>
    </div>
</template>

<script>
export default {
  name: 'ContactBranchItem',
  props: {
    branchInfo: {}
  },
  emits: [
    'onShowLocation'
  ],
  computed: {
    title () {
      if (!this.branchInfo) return ''
      return this.branchInfo?.name
    },
    address () {
      if (!this.branchInfo) return ''
      return this.branchInfo?.address
    },
    phone () {
      if (!this.branchInfo && !this.branchInfo?.phone) return ''
      return '+974 ' + this.branchInfo?.phone
    },
    schedule () {
      if (!this.branchInfo && !this.branchInfo?.schedule) return ''
      return this.branchInfo?.schedule
    },
    ramadanSchedule () {
      if (!this.branchInfo && !this.branchInfo?.ramadanSchedule) return ''
      return this.branchInfo?.ramadanSchedule
    },
    titleAddSchedule () {
      if (!this.branchInfo && !this.branchInfo?.titleAddSchedule) return ''
      return this.branchInfo?.titleAddSchedule
    }
  },
  methods: {
    onShowLocation () {
      this.$emit('onShowLocation')
    }
  }
}
</script>
