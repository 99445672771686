<template>
  <div :id="mapId" class="map"></div>
</template>

<script>
const markerIcon = require('@/assets/images/pin.svg')
export default {
  name: 'GoogleMap',
  props: {
    mapOptions: { type: Object, default: () => {} },
    googleApiKey: { type: String, default: '' }
  },
  emit: [
    'afterInit'
  ],
  data () {
    return {
      screenWidth: 0,
      map: null,
      mapLoader: false,
      mapId: 'google_map',
      dafaultMapOptions: {
        zoom: 10,
        center: { lat: 25.286106, lng: 51.534817 },
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: 'cooperative'
      }
    }
  },
  methods: {
    onResize () {
      this.screenWidth = document.documentElement.clientWidth
    },
    initMap () {
      this.map = new window.google.maps.Map(
        document.getElementById(this.mapId),
        { ...this.dafaultMapOptions, ...this.mapOptions })
    },
    addMarker ({ lat, lng }) {
      if (!this.map) return
      return new window.google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        icon: markerIcon,
        draggable: false,
        animation: window.google.maps.Animation.DROP
      })
    },
    deleteMarker (marker) {
      if (!this.map) return
      marker.setMap(null)
    },
    setMapCenter ({ lat, lng }) {
      if (!this.map) return
      if (this.screenWidth < 550) {
        this.map.setCenter(new window.google.maps.LatLng(lat - 0.014, lng))
      } else {
        this.map.setCenter(new window.google.maps.LatLng(lat, lng + 0.017))
      }

      this.map.setZoom(14)
    },
    setBounds (markers, zoom = 12) {
      if (!this.map) return
      const bounds = new window.google.maps.LatLngBounds()
      markers.forEach(m => {
        bounds.extend(m.getPosition())
      })
      this.map.setZoom(zoom)
      this.map.setCenter(bounds.getCenter())
    },
    loadGoogleMapsScript () {
      return new Promise((resolve, reject) => {
        const src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&callback=initMap`
        const scripts = [...document.querySelectorAll('script')]
        if (scripts && scripts.length) {
          const isScriptLoaded = scripts.reduce((acc, s) => {
            if (s.src === src) {
              acc = acc + 1
            }
            return acc
          }, 0)
          if (isScriptLoaded) {
            resolve()
            return
          }
        }
        const script = document.createElement('script')
        script.onload = () => {
          resolve()
          window.google.maps.event.addListener(this.map, 'idle', function () {
            window.google.maps.event.trigger(this.map, 'resize')
          })
        }
        script.async = true
        script.src = src
        document.head.appendChild(script)
      })
    }
  },
  created () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    this.onResize()
    // TODO: add hash for certin instance of map
    if (!this.googleApiKey) return
    this.mapLoader = true
    window.initMap = this.initMap.bind(this)
    this.loadGoogleMapsScript()
      .then(() => {
        this.mapLoader = false
        this.$emit('afterInit')
      })
  }
}
</script>

<style lang="scss" scoped>
  .map {
    position: static!important;
  }
</style>
